<template>
  <g v-bind:transform="'translate(' + x +',' + y + ')'">
    <circle
        cx="0"
        cy="0"
        r="15"
        style="stroke-width:12; fill:yellow"
        stroke="yellow"
    />
  </g>
</template>

<script>

export default {
  name: 'ControlPoint',
  props: [
    'x', 'y'
  ]
}

</script>
