<template>
  <div class="menu" style="left:0">
    <div class="item" v-for="notification in notifications" :key="notification">
      {{ notification }}
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";

export default {
  name: "Notifications",
  computed: {
    notifications() {
      const store = useStore();
      return store.state.notifications.notifications;
    }
  }
}
</script>

