<template>
  <g>
    <line x1="-30"
          y1="35"
          x2="30"
          y2="35"
          stroke-width="16"
          stroke="#392F32"
    />

    <line x1="-30"
          y1="36"
          v-bind:x2="health"
          y2="36"
          stroke-width="14"
          v-bind:stroke="colour"
    />
  </g>
</template>

<script>
export default {
  name: "Healthbar",
  props: ['totalHealth', 'damage'],
  computed: {
    colour() {
      const health = this.healthPercent;

      if (health > .6) {
        return 'green';
      } else if (health > .4) {
        return 'yellow';
      } else if (health > .2) {
        return 'orange';
      }

      return 'red';
    },
    healthPercent() {
      return (this.totalHealth - this.damage)/this.totalHealth;
    },
    health() {
      return (this.healthPercent * 60) - 30;
    }
  },
}
</script>
