<template>
  <div class="mx-auto container flex place-content-center md:text-md sm:text-sm">
    <div class="nodes-box nodes-text-white w-1/4">Player 1<span v-if="isPlayer(1)"> (You)</span>: {{ scores[1] }}</div>
    <div class="nodes-box nodes-text-white w-1/4">Player 2<span v-if="isPlayer(2)"> (You)</span>: {{ scores[2] }}</div>
    <div class="nodes-box nodes-text-white w-1/4">Timeleft: {{ timeleft[0] }}:{{ timeleft[1] }}</div>
  </div>
</template>

<script>
import {useStore} from "vuex";

export default {
  name: 'Scorebar',
  computed: {
    scores () {
      const store = useStore();
      return store.state.game.scores;
    },
    playerIndex () {
      const store = useStore();
      return store.state.game.playerIndex;
    },
  },
  data () {
    return {
      timeleft: [20, 0]
    }
  },
  created() {
    this.countdown();
  },
  methods: {
    isPlayer(playerId) {
      return playerId === this.playerIndex;
    },
    countdown() {
      this.timeleft[1]--;
      if (this.timeleft[1] < 0) {
        this.timeleft[1] = 59;
        this.timeleft[0]--;
      }
      setTimeout(this.countdown, 1000);
    }
  }
}
</script>
