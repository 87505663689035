<template>
  <div>
    <div class="menu" style="right:0" v-show="menu === 'core'">
      <div class="item" @click="retrofit('shield')">
        Retrofit Shields
      </div>
      <div class="item" @click="retrofit('weapon')">
        Retrofit Weapons
      </div>
      <div v-show="node.isCore" class="item" @click="research('shield')">
        Research Shields
      </div>
      <div v-show="node.isCore" class="item" @click="research('weapon')">
        Research Weapons
      </div>
      <div v-show="node.isCore" class="item" @click="build">
        Build new node
      </div>
      <div v-show="!node.isCore" class="item" @click="upgrade()">
        Upgrade node
      </div>
    </div>

    <div class="menu" style="right:0" v-show="menu === 'focus'">
      <div class="item" @click="focus(1)">Increase power</div>
      <div class="item" @click="focus(0)">Increase efficiency</div>
    </div>

    <div class="menu" style="right:0" v-show="menu === 'shield'">
      <div class="item" v-for="shield in shields" :key="shield.id" @click="shieldClick(shield.id)">
        Shield S: {{ shield.sink }} R: {{ shield.regen }}
        Gen: {{ shield.generation }}
        <span v-for="type in shield.resistances" :key="type.damageType">{{ type.damageType }}:{{ type.damageQuantity }}</span>
      </div>
    </div>

    <div class="menu" style="right:0" v-show="menu === 'weapon'">
      <div class="item" v-for="weapon in weapons" :key="weapon.id" @click="weaponClick(weapon.id)">
        Weapon Y: {{ weapon.yield }} R: {{ weapon.range }}
        Gen: {{ weapon.generation }}
        <span v-for="type in weapon.damageTypes" :key="type.damageType">{{ type.damageType }}:{{ type.damageQuantity }}</span>
      </div>
    </div>

  </div>
</template>

<script>
import {useStore} from "vuex";

export default {
  name: 'Menu',
  props: ['node'],
  inject: ['$sendGameCmd'],
  data() {
    return {
      menu: 'core',
      actionType: '',
      action: '',
      researchFocus: 0,
      buildShield: 0,
    }
  },
  computed: {
    shields() {
      const store = useStore();
      return store.state.game.shields;
    },
    weapons() {
      const store = useStore();
      return store.state.game.weapons;
    },
  },
  methods: {
    upgrade() {
      this.$sendGameCmd('upgrade', {node: this.node.id});
      this.$emit('done');
    },
    retrofit(type) {
      this.actionType = type;
      this.action = 'retrofit';
      this.menu = type;
    },
    research(type) {
      this.actionType = type;
      this.action = 'research';
      this.menu = 'focus';
    },
    build() {
      this.action = 'build';
      this.menu = 'shield';
    },
    focus(type) {
      this.researchFocus = type;
      this.menu = this.actionType;
    },
    shieldClick(item) {
      if (this.action === 'retrofit') {
        this.$sendGameCmd(this.action, {node: this.node.id, item: item, type: this.actionType});
        this.$emit('done');
      }

      if (this.action === 'research') {
        this.$sendGameCmd(this.action, {node: this.node.id, type: this.actionType, item: item, focus: this.researchFocus});
        this.$emit('done');
      }

      if (this.action === 'build') {
        this.menu = 'weapon';
        this.buildShield = item;
      }
    },
    weaponClick(item) {
      if (this.action === 'retrofit') {
        this.$sendGameCmd(this.action, {node: this.node.id, item: item, type: this.actionType});
        this.$emit('done');
      }

      if (this.action === 'research') {
        this.$sendGameCmd(this.action, {node: this.node.id, type: this.actionType, item: item, focus: this.researchFocus});
        this.$emit('done');
      }

      if (this.action === 'build') {
        this.$emit('build', {node: this.node.id, shield: this.buildShield, weapon: item});
      }
    },
  }
}
</script>
