<template>
  <div ref="container" class="container h-screen mx-auto">
  <Scorebar></Scorebar>
  <Notifications></Notifications>
  <svg ref="svg" style="background-color:#000" v-bind:width="gridWidth" v-bind:height="gridHeight" version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1560 780">
    <g :transform="screenScale">
    <g :transform="gridscale">
      <grid></grid>

      <g transform="translate(3600,300)" ref="mapLayer">
        <g><ControlPoint v-for="cp in cps" v-bind:key="cp" :x="cp.x" :y="cp.y" /></g>
        <g>
          <Projectile
            v-for="projectile in projectiles"
            v-bind:key="projectile.id"
            v-bind:from="projectile.from"
            v-bind:to="projectile.to"
            @done="removeProjectile(projectile.id)"
          />
        </g>
        <g>
          <Node
            v-for="node in nodes"
            v-bind:key="node.nodeHash"
            v-bind:node="node"
            :is-active="isActiveNode(node.nodeHash)"
            :is-building="isNodeBuilding(node.nodeHash)"
            @selected="selectNode"
            @build="buildNode"
          />
        </g>
      </g>
    </g>
    </g>
  </svg>
  <Menu
      v-if="menuIsActive"
      :node="activeNode"
      @done="menuDone"
      @build="build"
  />
  </div>

</template>

<script>
import Scorebar from "@/components/nodes/Scorebar";
import Grid from "@/components/nodes/Grid";
import ControlPoint from "@/components/nodes/ControlPoint";
import Projectile from "@/components/nodes/Projectile";
import Node from "@/components/nodes/Node";
import Menu from "@/components/nodes/Menu";
import {useStore, mapActions} from "vuex";
import router from "@/router";
import Notifications from "@/components/nodes/Notifications";
import {ref} from "@vue/reactivity";

export default {
  name: 'Nodes',
  components: {
    Node,
    Projectile,
    ControlPoint,
    Scorebar,
    Grid,
    Menu,
    Notifications
  },
  inject: ['$sendGameCmd'],
  setup() {
    const container = ref({clientWidth:0, clientHeight:0});
    const mapLayer = ref({});
    const svg = ref({});
    return {
      svg,
      mapLayer,
      container
    }
  },
  data () {
    return {
      activeNodeHash: '',
      menuIsActive: false,
      activeNode: {},
      nodeIsBuilding: false,
      buildProps: {}
    }
  },
  created() {
    const store = useStore();
    if (!store.state.game.inProgress) {
      router.push('/');
    }
  },
  //watch: [gamemap.innerHeight, gamemap.innerHeight],
  computed: {
    svgwidth() { return window.innerWidth; },
    svgheight() { return window.innerHeight; },
    gridWidth() {
      return '100%';// this.container.clientWidth;
    },
    gridHeight() {
      return '100%'; //this.container.clientHeight;
    },
    gridscale() { return 'scale(0.2)'; },
    screenScale() {
      return 'scale(1)'; // + this.container.clientWidth / (2*3600*.2) + ')';
    },
    cps() {
      const scale = 30;
      let cps = [];

      for (let x=-100; x<110; x+=10) {
        for (let y=-0; y<110; y+=10) {
          cps.push({ x: x*scale, y: y*scale });
        }
      }

      return cps;
    },
    projectiles() {
      const store = useStore();
      return store.state.game.projectiles;
    },
    nodes() {
      const store = useStore();
      return store.state.game.nodes;
    }
  },
  methods: {
    ...mapActions(['removeProjectile']),
    isActiveNode(nodeId) {
      return this.activeNodeHash === nodeId;
    },
    isNodeBuilding(nodeId) {
      return this.activeNodeHash === nodeId && this.nodeIsBuilding;
    },
    selectNode(nodeHash) {
      this.menuDone();
      this.$nextTick(function () {
        this.activeNodeHash = nodeHash;
        this.menuIsActive = true;
        this.activeNode = this.nodes[nodeHash];
      });
    },
    menuDone() {
      this.activeNodeHash = '';
      this.menuIsActive = false;
      this.activeNode = {};
      this.nodeIsBuilding = false;
    },
    build(props) {
      this.nodeIsBuilding = true;
      this.buildProps = props;
      this.menuIsActive = false;
    },
    buildNode(position) {
      let pt = this.svg.createSVGPoint();
      pt.x = position.x;
      pt.y = position.y;
      pt = pt.matrixTransform(
        this.mapLayer.getScreenCTM().inverse()
      );

      this.buildProps.x = pt.x / 30;
      this.buildProps.y = pt.y / 30;

      this.$sendGameCmd('build', this.buildProps);

      this.activeNodeHash = '';
      this.activeNode = {};
      this.buildProps = {};
      this.nodeIsBuilding = false;
    }
  }
}
</script>
