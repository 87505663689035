<template>
  <g v-bind:transform="'translate(' + position.x + ',' + position.y + ')'">
    <circle
        cx="0"
        cy="0"
        v-bind:r="range"
        style="stroke-width:4; fill:none"
        v-bind:stroke="colour(node.playerId)"
    />
    <circle
        cx="0"
        cy="0"
        :r="buildrange"
        style="stroke-width:12; fill:rgba(0,255,0,0.2)"
        stroke="green"
        stroke-dasharray="25, 25"
        @click="buildClicked"
        v-show="isBuilding"
    />
    <g>
      <circle
          cx="0"
          cy="0"
          r="100"
          style="stroke-width:12; fill:none"
          v-bind:stroke="statusColour"
          stroke-dasharray="25, 25"
          v-show="isCurrentPlayers(node.playerId)"
      />
      <g transform="scale(1.7)" @click="select">
        <polygon
            v-bind:fill="fill"
            v-bind:stroke="colour(node.playerId)"
            stroke-width="4"
            points="0,-35 30,17 -30,17"
        />
        <Healthbar :damage="damage" :total-health="health" v-if="showHealth"></Healthbar>
      </g>
    </g>
  </g>
</template>

<script>
import Healthbar from "@/components/nodes/Healthbar";
import {useStore} from "vuex";

export default {
  name: 'Node',
  components: {Healthbar},
  props: [
    'node',
    'isActive',
    'isBuilding',
  ],
  setup(props) {
    const scale = 30;
    return {
      scale: scale,
      position: {x: props.node.position.x * scale, y: props.node.position.y * scale},
    };
  },
  computed: {
    fill() {
      return this.node.isCore ? 'blue': 'black';
    },
    range() {
      if (!this.isCurrentPlayers(this.node.playerId)) {
        return 0;
      }

      return this.node.weapon.range * this.scale;
    },
    buildrange() {
      return this.range * 2;
    },
    showHealth() {
      return this.isCurrentPlayers(this.node.playerId);
    },
    health() {
      if (!this.isCurrentPlayers(this.node.playerId)) {
        return 0;
      }

      return this.node.shield.sink * (this.node.isCore ? 2 : 1);
    },
    damage() {
      if (!this.isCurrentPlayers(this.node.playerId)) {
        return 0;
      }

      return this.node.damage;
    },
    playerIndex() {
      const store = useStore();
      return store.state.game.playerIndex;
    },
    statusColour() {
      return this.isActive ? 'yellow' : (this.node.busy ? 'red' : 'green');
    },
  },
  methods: {
    colour(playerId) {
      if (this.playerIndex === playerId) {
        return 'blue';
      }

      return 'red';
    },
    isCurrentPlayers(playerId) {
      return this.playerIndex === playerId;
    },
    select() {
      if (this.isCurrentPlayers(this.node.playerId)) {
        this.$emit('selected', this.node.nodeHash);
      }
    },
    buildClicked(ev) {
      const scale = 30;
      const zoom = 0.2;
      const xorig = 130;
      const yorig = 10;
      let position = {x: (ev.clientX/(zoom*scale)) - xorig, y: ev.clientY/(zoom*scale) - yorig};
      position.x = ev.clientX;
      position.y = ev.clientY;
      this.$emit('build', position);
    }
  }
}
</script>
