<template>
  <g>
    <line v-for="point in ylines" v-bind:key="point"
          x1="0"
          v-bind:x2="xmax"
          v-bind:y1="point"
          v-bind:y2="point"
          stroke="#4d4d4d"
          vector-effect="non-scaling-stroke"
    />
    <line v-for="point in xlines" v-bind:key="point"
          y1="0"
          v-bind:y2="ymax"
          v-bind:x1="point"
          v-bind:x2="point"
          stroke="#4d4d4d"
          vector-effect="non-scaling-stroke"
    />
  </g>
</template>

<script>

export default {
  name: 'Grid',
  inject:['$gameMap'],
  computed: {
    squaresX () {
      return this.$gameMap.xMax - this.$gameMap.xMin;
    },
    squaresY () {
      return this.$gameMap.yMax - this.$gameMap.yMin;
    },
    xmax () {
      return this.squaresX * this.$gameMap.scale;
    },
    ymax () {
      return this.squaresY * this.$gameMap.scale;
    },
    xlines () {
      let xlines = [];
      for (let i=0;i<=this.squaresX;i=i+10) {
        xlines.push(i*this.$gameMap.scale);
      }

      return xlines;
    },
    ylines () {
      let ylines = [];
      for (let i=0;i<=this.squaresY;i=i+10) {
        ylines.push(i*this.$gameMap.scale);
      }

      return ylines;
    }
  }
}
</script>
