<template>
<Nav></Nav>
<div class="nodes-box container mx-auto p-4">
  <div class="nodes-text-white text-center md:text-8xl sm:text-4xl">Game over</div>
  <div class="nodes-text-white text-left md:text-4xl sm:text-2xl">
    Winner: <br />
    <span v-if="scores[1] > scores[2]">Player 1 <span v-if="isPlayer(1)"> (You)</span></span>
    <span v-if="scores[1] < scores[2]">Player 2 <span v-if="isPlayer(2)"> (You)</span></span>
    <span v-if="scores[1] === scores[2]">Draw</span>
  </div>
  <br /><br />
  <div class="nodes-text-white text-left md:text-4xl sm:text-2xl">Final Scores</div>
  <div class="nodes-text-white text-left md:text-lg sm:text-md">Player 1<span v-if="isPlayer(1)"> (You)</span>: {{ scores[1] }}</div>
  <div class="nodes-text-white text-left md:text-lg sm:text-md">Player 2<span v-if="isPlayer(2)"> (You)</span>: {{ scores[2] }}</div>
  <br />
  <div class="nodes-box-blank nodes-text-red p-2 ml-auto mr-2 w-1/12 sm:w-1/6 text-center"> <router-link to="/">Play again</router-link></div>
</div>
</template>

<script>
import Nav from "@/components/Nav";
import {useStore} from "vuex";

export default {
  name: "GameOver",
  components: {
    Nav
  },
  computed: {
    scores () {
      const store = useStore();
      return store.state.game.scores;
    },
    playerIndex () {
      const store = useStore();
      return store.state.game.playerIndex;
    },
  },
  methods: {
    isPlayer(playerId) {
      return playerId === this.playerIndex;
    },
  }
}
</script>

