<template>
  <circle
      cx="0"
      cy="0"
      r="7"
      style="stroke-width:12; fill:rgba(0,255,0,0.2)"
      v-bind:transform="'translate(' + position.x + ',' + position.y + ')'"
      stroke="green"
  />
</template>

<script>

export default {
  name: 'Projectile',
  props: [
    'from', 'to'
  ],
  data () {
    return {
      colour: 'yellow',
      position: {x: 0, y: 0},
      loops: 0,
    }
  },
  created() {
    this.animate();
  },
  methods: {
    animate() {
      const scale = 30;
      const incX = (this.from.x - this.to.x)/15;
      const incY = (this.from.y - this.to.y)/15;

      let newX = this.from.x - (incX*this.loops);
      let newY = this.from.y - (incY*this.loops);
      this.loops++;

      this.position.x = newX*scale;
      this.position.y = newY*scale;

      if (this.loops > 15) {
        this.position = {x: 0, y: 0};
        this.$emit('done');
        return;
      }

      setTimeout(this.animate, 100);
    }
  }
}

</script>
